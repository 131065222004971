.contact-container {
  padding: 40px 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-header {
  text-align: center;
  margin-bottom: 40px;
}

.contact-header h1 {
  font-size: 3rem;
  color: rgb(41, 140, 46);
}

.contact-header p {
  font-size: 1.4rem;
  color: #555;
}

/* Contact Content Layout */
.contact-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1200px; 
}

.contact-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #333;
}

.input-icon {
  position: relative;
}

.input-icon i {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px 10px 10px 40px; 
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: rgb(41, 140, 46);
  box-shadow: 0 0 5px rgba(41, 140, 46, 0.3);
}

.form-group textarea {
  resize: vertical;
  min-height: 150px;
}

button[type="submit"] {
  display: inline-block;
  padding: 12px 25px;
  background-color: rgb(41, 140, 46); 
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #28a745; 
}

/* Contact Details Section */
.contact-details {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.contact-details h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: rgb(41, 140, 46); 
}

.contact-details p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

.contact-details i {
  margin-right: 10px;
  color: rgb(41, 140, 46); /* Icon color */
}

/* Pop-Up Box Styles */
.popup-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensures popup is on top */
  padding: 20px; /* Padding to ensure content isn't flush against the edges */
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 100%; /* Width is 100% of the available space within the padding */
}

.popup-content p {
  font-size: 1.2rem;
  color: rgb(41, 140, 46); /* Main company color */
  margin-bottom: 20px;
}

.popup-content button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46); /* Main company color */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-content button:hover {
  background-color: #28a745; /* Slightly lighter shade of the main color */
}


/* Responsive Styles */
@media (max-width: 768px) {
  .contact-header h1 {
    font-size: 2.4rem;
  }

  .contact-header p {
    font-size: 1.2rem;
  }

  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-form {
    padding: 20px;
    max-width: 100%;
  }

  .contact-details {
    margin-top: 20px;
    padding: 15px;
    width: 100%; /* Full width on smaller screens */
    max-width: 100%;
  }

  .popup-content {
    max-width: 90%; /* Reduce max-width on smaller screens */
  }

  .popup-content p {
    font-size: 1rem; /* Slightly smaller font size for readability */
  }

  .popup-content button {
    font-size: 0.9rem; /* Adjust button font size */
    padding: 8px 16px; /* Adjust button padding */
  }
}

@media (max-width: 480px) {
  .contact-header h1 {
    font-size: 2rem;
  }

  .contact-header p {
    font-size: 1.1rem;
  }

  .contact-form {
    padding: 15px;
  }

  .form-group input,
  .form-group textarea {
    padding: 10px;
    font-size: 0.9rem;
  }

  button[type="submit"] {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .contact-details h2 {
    font-size: 1.6rem;
  }

  .contact-details p {
    font-size: 1rem;
  }

  .popup-content {
    max-width: 100%; /* Full width on very small screens */
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .popup-content p {
    font-size: 0.9rem; /* Smaller font size for very small screens */
  }

  .popup-content button {
    font-size: 0.8rem; /* Further adjust button font size */
    padding: 6px 12px; /* Further adjust button padding */
  }
}
