/* Footer Styles */
.footer-container {
  background: linear-gradient(90deg, #073009, #298c2e);
  padding: 40px 20px;
  text-align: center;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Align items to the top */
}

.footer-logo {
  flex: 1;
  text-align: left;
  margin-bottom: 20px; 
}

.footer-logo img {
  height: 60px;
  width: auto;
  transition: transform 0.3s ease;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));
}

.footer-logo img:hover {
  transform: scale(1.1);
}

.footer-links {
  flex: 2;
  text-align: center;
  margin-bottom: 20px;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: 0;
}

.footer-links li {
  display: inline;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-links a:hover,
.footer-links a:focus {
  color: #ffcc00;
  transform: translateY(-2px);
}

.footer-social {
  flex: 1;
  text-align: right;
  margin-bottom: 20px;
}

.footer-social a {
  color: #fff;
  font-size: 1.8rem;
  transition: color 0.3s ease, transform 0.3s ease;
  margin-left: 10px;
}

.footer-social a:hover,
.footer-social a:focus {
  color: #ffcc00;
  transform: scale(1.2);
}

.footer-download {
  width: 100%; 
  text-align: center;
  margin-bottom: 20px; 
}

.footer-download h3 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.download-links {
  display: flex;
  justify-content: center;
  align-items: center; /* Align items vertically */
  gap: 20px;
}

/* Google Play Button */
.download-links .google-play {
  display: inline-block;
  width: 130px; /* Specific width for Google Play */
  height: 40px; /* Specific height for Google Play */
}

.download-links .google-play img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
  transition: transform 0.3s ease;
}

/* Apple App Store Button */
.download-links .app-store {
  display: inline-block;
  width: 120px; /* Specific width for Apple Store */
  height: 40px; /* Specific height for Apple Store */
}

.download-links .app-store img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: contain;
  transition: transform 0.3s ease;
}

.download-links img:hover,
.download-links img:focus {
  transform: scale(1.1); /* Slightly enlarge on hover */
}

.footer-contact {
  width: 100%; 
  text-align: center;
  margin-top: 20px;
  color: #fff;
}

.footer-contact p {
  margin: 5px 0;
  font-weight: 400;
  font-size: 1.1rem;
}

.footer-contact a {
  color: #fff;
  text-decoration: none;
}

.footer-contact a:hover,
.footer-contact a:focus {
  color: #ffcc00;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo,
  .footer-links,
  .footer-social,
  .footer-download,
  .footer-contact {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .footer-links ul {
    flex-direction: column;
    gap: 15px;
  }

  .footer-social a {
    margin-left: 10px;
  }

  .download-links .google-play,
  .download-links .app-store {
    width: 100px; /* Adjust width for smaller screens */
    height: 35px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 480px) {
  .footer-contact p {
    font-size: 1rem;
  }

  .footer-download h3 {
    font-size: 1.2rem;
  }

  .footer-links ul {
    gap: 10px;
  }

  .download-links .google-play,
  .download-links .app-store {
    width: 80px; /* Further adjust width for very small screens */
    height: 30px; /* Further adjust height for very small screens */
  }
}
