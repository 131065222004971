.eggs-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px; 
}

.eggs-content {
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px); 
  transition: margin-left 0.3s ease; /* Smooth transition for sidebar toggle */
}

.actions {
  margin-bottom: 20px;
}

.move-to-chicks-button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.move-to-chicks-button:hover {
  background-color: #28a745;
}

.move-to-chicks-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.egg-table {
  margin-top: 20px;
  overflow-x: auto; /* Ensure table scrolls on smaller screens */
}

.egg-table table {
  width: 100%;
  border-collapse: collapse;
}

.egg-table th,
.egg-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.egg-table th {
  background-color: #f4f4f4;
}

.egg-table tr:hover {
  background-color: #f1f1f1;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows pagination buttons to wrap on smaller screens */
}

.pagination-button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.pagination-button:hover {
  background-color: #28a745;
}

.pagination-button.active {
  background-color: #28a745;
}

@media (max-width: 768px) {
  .eggs-content {
    margin-left: 0; /* Hide sidebar for mobile */
    width: 100%;
    padding-top: 70px; /* Space for the fixed header */
  }

  .egg-table th,
  .egg-table td {
    padding: 10px 8px; /* Reduce padding for better fit on mobile */
  }

  .egg-table table {
    font-size: 0.9rem; /* Slightly smaller text for mobile */
  }

  .pagination-controls {
    flex-direction: row; /* Keep pagination buttons in a row */
    flex-wrap: wrap; /* Allow wrapping */
  }
}

@media (max-width: 480px) {
  .eggs-content {
    margin-left: 0; /* Hide sidebar for small screens */
    width: 100%;
    padding-top: 70px;
  }

  .egg-table th,
  .egg-table td {
    padding: 8px 6px; /* Further reduce padding for smaller screens */
  }

  .egg-table table {
    font-size: 0.8rem; /* Smaller text for small screens */
  }

  .pagination-controls {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
