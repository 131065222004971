.pricing-container {
  padding: 40px 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Pricing Header */
.pricing-header {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.pricing-header h1 {
  font-size: 3rem;
  color: rgb(41, 140, 46); 
}

.pricing-header p {
  font-size: 1.4rem;
  color: #555;
}

/* Billing Toggle */
.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.billing-toggle span {
  font-size: 1.2rem;
  color: #555555;
  margin: 0 10px;
}

.toggle-switch {
  width: 60px;
  height: 30px;
  background-color: #8598ff;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-thumb {
  width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  transition: transform 0.3s ease;
}

.toggle-thumb.yearly {
  transform: translateX(30px);
}

/* Pricing Cards */
.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-width: 1200px; 
}

.pricing-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Badges */
.badge, .badge-2, .badge-3 {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.9rem;
}

.badge {
  background-color: #ffcc00;
}

.badge-2 {
  background-color: #67c0f4;
}

.badge-3 {
  background-color: #7d7d7d;
}

/* Pricing Card Content */
.pricing-card h2 {
  font-size: 2rem;
  margin-bottom: 10px;
  color: rgb(41, 140, 46); 
}

.price {
  font-size: 2rem;
  color: #28a745; 
  margin-bottom: 20px;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.pricing-card li {
  margin-bottom: 10px;
  font-size: 1rem;
  color: #555;
  display: flex;
  align-items: center;
}

.pricing-card li i {
  margin-right: 10px;
  color: #28a745; 
}

.pricing-card button {
  display: inline-block;
  padding: 12px 25px;
  background-color: rgb(41, 140, 46); 
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.pricing-card button:hover {
  background-color: #28a745; /* Slightly lighter shade of the main color */
  transform: translateY(-3px);
}

/* Highlight the most popular plan */
.popular-plan {
  background: linear-gradient(135deg, #28a745, #218838);
  color: #fff;
  transform: scale(1.05);
}

.popular-plan h2,
.popular-plan .price,
.popular-plan li i {
  color: #fff;
}

.popular-plan button {
  background-color: #fff;
  color: #28a745;
}

.popular-plan button:hover {
  background-color: #f8f9fa;
  color: #28a745;
}

/* Comparison Table */
.comparison-table {
  margin-top: 40px;
  text-align: center;
  width: 100%;
}

.comparison-table h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: rgb(41, 140, 46);
}

.comparison-table table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border-collapse: collapse;
}

.comparison-table th,
.comparison-table td {
  padding: 15px;
  border: 1px solid #ddd;
}

.comparison-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

.comparison-table td {
  text-align: center;
  font-size: 1rem;
}

.comparison-table td i {
  color: #28a745;
}

.comparison-table td i.fas.fa-times-circle {
  color: #e74c3c;
}

/* Call-to-Action Banner */
.cta-banner {
  background: linear-gradient(135deg, #333, #858585);
  color: #fff;
  text-align: center;
  padding: 40px 20px;
  border-radius: 8px;
  margin-top: 60px;
  width: 100%;
}

.cta-banner h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #fff;
}

.cta-banner p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-banner button {
  background-color: #fff;
  color: #28a745;
  font-weight: bold;
  padding: 12px 30px;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.cta-banner button:hover {
  background-color: #f8f9fa;
  transform: translateY(-3px);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .pricing-header h1 {
    font-size: 2.4rem;
  }

  .pricing-header p {
    font-size: 1.2rem;
  }

  .pricing-cards {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    max-width: 100%;
    margin-bottom: 20px; /* Adds space between stacked cards */
  }

  .comparison-table table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  .cta-banner {
    padding: 30px 15px;
  }

  .cta-banner h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .pricing-header h1 {
    font-size: 2rem;
  }

  .pricing-header p {
    font-size: 1.1rem;
  }

  .pricing-cards {
    width: 100%;
  }

  .pricing-card {
    padding: 20px;
  }

  .comparison-table th,
  .comparison-table td {
    padding: 10px;
  }

  .cta-banner h2 {
    font-size: 1.8rem;
  }

  .cta-banner p {
    font-size: 1rem;
  }

  .cta-banner button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}
