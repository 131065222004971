* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

/* Home Container */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
  width: 100%;
}

/* Hero Section */
.home-header {
  position: relative;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  margin-bottom: 60px;
  padding: 100px 20px;
  background: url('/public/images/hoedspruit.jpg') no-repeat center center/cover; 
  border-radius: 8px;
  color: #fff; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
}

.home-header h1 {
  font-size: 2.8rem;
  margin-bottom: 10px;
}

.home-header p {
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #ffcc00;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e6b800;
}

/* Features Section */
.home-features {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin: 0 auto;
  max-width: 1200px;
}

.feature {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1 1 calc(33.333% - 20px);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 100%;
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-icon {
  font-size: 2.5rem;
  color: #298c2e;
  margin-bottom: 15px;
}

.feature h2 {
  font-size: 1.7rem;
  margin-bottom: 15px;
  color: #298c2e;
}

.feature p {
  font-size: 1.1rem;
  color: #555;
}

/* Testimonials Section */
.testimonials {
  background-color: #e9f5e9;
  padding: 60px 20px;
  margin: 60px 0;
  border-radius: 8px;
  text-align: center;
  width: 100%;
}

.testimonials h2 {
  font-size: 2rem;
  color: #298c2e;
  margin-bottom: 40px;
}

.testimonial-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  max-width: 100%;
}

.testimonial {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.testimonial p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

.testimonial h3 {
  font-size: 1rem;
  color: #298c2e;
}

/* Newsletter Section */
.newsletter {
  background-color: #fff;
  padding: 60px 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
  width: 100%;
  max-width: 1200px;
}

.newsletter h2 {
  font-size: 2rem;
  color: #298c2e;
  margin-bottom: 20px;
}

.newsletter p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 20px;
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.newsletter-form input[type="email"] {
  padding: 10px;
  width: 100%;
  max-width: 400px;
  font-size: 1.1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
}

.newsletter-form button {
  padding: 10px 20px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #298c2e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter-form button:hover {
  background-color: #236e27;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .home-header h1 {
    font-size: 2.4rem;
  }

  .home-header p {
    font-size: 1.1rem;
  }

  .feature {
    flex: 1 1 calc(50% - 20px);
  }

  .newsletter-form input[type="email"] {
    max-width: 100%;
  }

  .testimonials h2 {
    font-size: 1.8rem;
  }

  .testimonial-cards {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .home-header h1 {
    font-size: 2rem;
  }

  .home-header p {
    font-size: 1rem;
  }

  .feature {
    flex: 1 1 100%;
  }

  .testimonial-cards {
    gap: 10px;
  }

  .testimonial {
    max-width: 100%;
  }

  .newsletter h2 {
    font-size: 1.6rem;
  }

  .newsletter-form input[type="email"],
  .newsletter-form button {
    width: 100%;
  }
}
