.login-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: linear-gradient(135deg, #e0f7fa, #e0f2f1);
}

.login-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #073009, #298c2e);
  color: #fff;
  text-align: center;
  padding: 40px;
}

.login-left h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.login-left p {
  font-size: 1.4rem;
  margin-bottom: 40px;
}

.login-image {
  width: 100%;
  height: 500px;
  background: url('/public/images/african_grey_parrot.jpg') no-repeat center center/cover;
  border-radius: 8px;
}

.login-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 40px;
}

.login-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login-form h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: rgb(41, 140, 46);
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #333;
}

.form-group input {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus {
  border-color: rgb(41, 140, 46);
  box-shadow: 0 0 5px rgba(41, 140, 46, 0.3);
}

.login-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: rgb(41, 140, 46); 
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
}

.login-button:hover {
  background-color: #28a745;
  transform: translateY(-3px);
}

.forgot-password {
  margin-top: 20px;
  font-size: 1rem;
}

.forgot-password a {
  color: #28a745;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password a:hover {
  color: #1e7e34;
}

.signup-link {
  margin-top: 20px;
  font-size: 1rem;
}

.signup-link a {
  color: rgb(41, 140, 46);
  text-decoration: none;
  transition: color 0.3s ease;
}

.signup-link a:hover {
  color: #28a745;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-left,
  .login-right {
    flex: none;
    width: 100%;
  }

  .login-left {
    padding: 20px;
  }

  .login-right {
    padding: 20px;
  }

  .login-form {
    padding: 20px;
  }

  .login-left h1 {
    font-size: 2.4rem;
  }

  .login-left p {
    font-size: 1.2rem;
  }

  .login-image {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .login-left h1 {
    font-size: 2rem;
  }

  .login-left p {
    font-size: 1rem;
  }

  .login-form h2 {
    font-size: 1.8rem;
  }

  .login-form {
    padding: 15px;
  }

  .login-button {
    padding: 10px 20px;
    font-size: 1rem;
  }

  .forgot-password, .signup-link {
    font-size: 0.9rem;
  }
}
