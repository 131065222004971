/* Client Dashboard Styles */
.client-dashboard-container {
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  width: 100%;
}

.client-dashboard-content {
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px);
  flex: 1;
  display: flex;
  flex-direction: column;
}

.client-user-info {
  margin-bottom: 20px;
}

.client-metrics-summary {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}

.client-metric-card {
  background-color: #b2ed84;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 1 150px;
  max-width: 250px;
  margin-bottom: 20px;
}

.client-metric-card h4 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.client-metric-card p {
  font-size: 1.5rem;
  font-weight: bold;
}

.client-charts-section {
  margin-top: 30px;
}

.client-charts-section h3 {
  margin-bottom: 20px;
}

.client-charts-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.client-chart-item {
  flex: 1 1 300px;
  max-width: 100%;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
}

.client-chart-item h4 {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #333;
}

.client-tasks-section {
  margin-top: 50px;
}

.client-task-input {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.client-task-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.client-task-input button {
  padding: 10px 20px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex-shrink: 0;
}

.client-task-input button:hover {
  background-color: #218838;
}

.client-tasks-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.client-tasks-table td.status.client-completed {
  background-color: #d4edda;
  color: #155724;
  font-weight: bold;
}

.client-tasks-table td.status.client-pending {
  background-color: #f8d7da;
  color: #721c24;
  font-weight: bold;
}

.client-tasks-table th,
.client-tasks-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.client-tasks-table th {
  background-color: #dafee5;
}

.client-tasks-table button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.client-tasks-table button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .client-dashboard-content {
    margin-left: 220px;
    width: calc(100% - 220px);
    padding: 15px;
  }

  .client-metric-card {
    flex: 1 1 200px;
    max-width: 200px;
  }
}

@media (max-width: 768px) {
  .client-dashboard-container {
    flex-direction: column;
  }

  .client-dashboard-content {
    margin-left: 0;
    width: 100%;
    padding: 10px;
    transition: margin-left 0.3s ease;
  }

  .client-metrics-summary,
  .client-charts-container {
    flex-direction: column;
    gap: 10px;
  }

  .client-chart-item,
  .client-metric-card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .client-task-input {
    flex-direction: column;
    gap: 5px;
  }

  .client-tasks-table th,
  .client-tasks-table td {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .client-dashboard-content {
    padding: 8px;
  }

  .client-chart-item,
  .client-metric-card {
    margin-bottom: 15px;
  }

  .client-task-input input {
    width: 100%;
    margin-bottom: 10px;
  }

  .client-task-input button {
    width: 100%;
  }

  .client-tasks-table th,
  .client-tasks-table td {
    font-size: 0.9rem;
  }
}
