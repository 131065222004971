/* Client Header Styles */
.client-header {
  background-color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.client-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.client-header-left {
  display: flex;
  align-items: center;
}

/* Hamburger menu button */
.sidebar-toggle {
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: #2c3e50;
  margin-right: 10px;
}

.client-header-logo {
  height: 40px;
  margin-right: 10px; 
}

.client-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%; /* Take full width to align elements properly */
}

.client-header-search {
  border: 1px solid #ddd;
  border-radius: 25px;
  padding: 5px 10px; 
  font-size: 0.9rem; /* Slightly smaller font size for mobile */
  margin-right: 10px; 
  flex-grow: 1; /* Allow search bar to grow to fill available space */
}

.client-header-icons i {
  font-size: 1.5rem;
  margin-left: 10px;
  cursor: pointer;
}

/* Mobile Navigation Menu */
.client-mobile-nav {
  position: absolute;
  top: 70px; /* Below the header */
  left: 0;
  right: 0;
  background-color: #2c3e50; /* Background color for the dropdown */
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; /* On top of other content */
}

.client-mobile-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.client-mobile-nav li {
  margin-bottom: 10px;
}

.client-mobile-nav a {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  display: block;
  padding: 10px 0;
}

.client-mobile-nav .client-logout-button {
  background: none;
  border: none;
  color: #f05d5d;
  font-size: 1.2rem;
  padding: 10px 0;
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.client-mobile-nav .client-logout-button:hover {
  color: #ffcc00;
}

/* Responsive styles */
@media (min-width: 768px) {
  .sidebar-toggle {
    display: none; /* Hide hamburger icon on desktop view */
  }
}

@media (max-width: 768px) {
  .client-header {
    padding: 0 10px; 
    height: 60px; /* Slightly smaller header height */
  }

  .client-header-logo {
    height: 35px; /* Adjust logo size */
    margin-right: 8px;
  }

  .client-header-search {
    width: 100%; /* Full width for search bar */
    margin-right: 5px;
  }

  .sidebar-toggle {
    margin-right: 8px;
  }

  .client-header-icons i {
    margin-left: 8px;
  }
}
