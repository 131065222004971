.client-sidebar {
  width: 250px;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  left: 0;
  background-color: #2c3e50;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 0 15px 15px 0;
  overflow-y: auto;
  transition: transform 0.3s ease;
  z-index: 999; /* Ensure sidebar is on top */
}

/* Sidebar hidden on mobile */
@media (max-width: 768px) {
  .client-sidebar {
    display: none; /* Hide on mobile screens */
  }
}

/* Sidebar Navigation */
.client-sidebar-nav {
  width: 100%;
  padding: 0 20px;
}

.client-sidebar-nav ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.client-sidebar-nav li {
  width: 100%;
}

.client-sidebar-nav a {
  display: block;
  padding: 15px 20px;
  color: #f1ecec;
  text-decoration: none;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s ease, color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.2rem;
}

.client-logout-button {
  display: block;
  padding: 15px 20px;
  color: #ff5e5e;
  text-decoration: none;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s ease, color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.2rem;
}

.client-sidebar-nav a:hover {
  background-color: #34495e;
  color: #ffffff;
}

.client-logout-button:hover {
  background-color: #34495e;
  color: #ff5e5e;
}
