.profile-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px; /* Account for fixed header */
}

.profile-content {
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px);
  transition: margin-left 0.3s ease; /* Smooth transition for sidebar toggle */
}

.profile-header {
  margin-bottom: 20px;
  text-align: center;
}

.profile-header h1 {
  font-size: 2rem;
  margin-bottom: 5px;
}

.profile-header p {
  color: #888;
}

.profile-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-card {
  display: flex;
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.profile-pic-section {
  flex: 1;
  text-align: center;
  margin-right: 30px;
}

.profile-pic {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-bottom: 15px;
}

.profile-pic-section h2 {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.profile-pic-section p {
  color: #888;
}

.profile-info-section {
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.profile-fields {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
  max-width: 400px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.profile-actions {
  text-align: center;
  margin-top: 20px;
}

.profile-actions button {
  padding: 10px 20px;
  background-color: #007bff; 
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 10px;
}

.profile-actions .cancel-button {
  background-color: #dc3545; 
}

.profile-actions button:hover {
  background-color: #0056b3;
}

.profile-actions .cancel-button:hover {
  background-color: #c82333;
}

.choose-image-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  transition: background-color 0.3s ease;
  position: relative;
  overflow: hidden;
}

.choose-image-button input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.choose-image-button:hover {
  background-color: #0056b3;
}

.uploading {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #888;
}

@media (max-width: 768px) {
  .profile-content {
    margin-left: 0;
    width: 100%;
    padding-top: 70px; /* Space for the fixed header */
  }

  .profile-card {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .profile-pic-section {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .profile-info-section {
    gap: 10px;
    justify-content: center;
  }

  .profile-actions {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .profile-content {
    margin-left: 0;
    width: 100%;
    padding-top: 70px;
  }

  .profile-header h1 {
    font-size: 1.5rem;
  }

  .profile-pic {
    width: 100px;
    height: 100px;
  }

  .form-group input {
    font-size: 0.9rem;
  }

  .profile-actions button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}
