.about-container {
  padding: 40px 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Hero Section */
.hero-section {
  text-align: center;
  margin-bottom: 60px;
  padding: 100px 20px;
  position: relative; /* Positioning for pseudo-elements */
  background: url('/public/images/cages-aerial.jpg') no-repeat center center/cover;
  border-radius: 8px;
  color: #fff;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevents overflow from pseudo-elements */
}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit; /* Uses the same background as the parent */
  filter: blur(1px); /* Applies a slight blur */
  z-index: 1; /* Positions it below the text */
}

.hero-section::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4); /* Adds a dark overlay for better text contrast */
  z-index: 2; /* Overlay above the blurred background but below the text */
}

.hero-section * {
  position: relative;
  z-index: 3; /* Positions text above the blur and overlay */
}

.hero-section h1 {
  font-size: 3.2rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allows buttons to wrap on smaller screens */
}

.cta-button {
  padding: 12px 30px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #28a745;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  text-align: center;
}

.cta-button.secondary {
  background-color: #ffcc00;
}

.cta-button:hover {
  background-color: #218838;
}

/* About Content */
.about-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 60px;
}

.about-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap; /* Allows content to wrap on smaller screens */
}

.about-image {
  flex: 1;
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* scales responsively */
}

.about-text {
  flex: 2;
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: rgb(41, 140, 46);
}

.about-section p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
}

.values-list {
  list-style: none;
  padding: 0;
}

.values-list li {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

.values-list li i {
  color: #28a745;
  margin-right: 10px;
}

/* Reverse layout for the middle section */
.reverse-layout {
  flex-direction: row-reverse;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-section {
    padding: 80px 20px;
  }

  .hero-section h1 {
    font-size: 2.8rem;
  }

  .hero-section p {
    font-size: 1.3rem;
  }

  .about-section {
    flex-direction: column;
    text-align: center;
  }

  .about-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .reverse-layout {
    flex-direction: column;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 2.4rem;
  }

  .hero-section p {
    font-size: 1.2rem;
  }

  .about-section {
    padding: 20px;
  }

  .about-text {
    font-size: 1rem;
  }

  .about-section h2 {
    font-size: 1.6rem;
  }

  .cta-button {
    width: 100%;
    padding: 10px 0;
  }
}
