.chicks-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px; 
}

.chicks-content {
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px); 
  transition: margin-left 0.3s ease; /* Smooth transition for sidebar toggle */
}

.chick-table {
  margin-top: 20px;
  overflow-x: auto; /* Ensure table scrolls on smaller screens */
}

.chick-table table {
  width: 100%;
  border-collapse: collapse;
}

.chick-table th,
.chick-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.chick-table th {
  background-color: #f4f4f4;
}

.chick-table tr:hover {
  background-color: #f1f1f1;
}

/* button styles */
.chick-table button {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin-right: 5px;
  transition: background-color 0.3s ease;
}

/* Edit Button */
.chick-table .edit-button {
  background-color: #007bff; /* Blue */
  color: white;
}

.chick-table .edit-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.chick-table .save-button {
  background-color: #52c482; /* Green */
  color: white;
}

.chick-table .save-button:hover {
  background-color: #4da75a; /* Darker green on hover */
}

/* Export Button */
.chick-table .export-button {
  background-color: #28a745; /* Green */
  color: white;
}

.chick-table .export-button:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Sell Button */
.chick-table .sell-button {
  background-color: #dc3545; /* Red */
  color: white;
}

.chick-table .sell-button:hover {
  background-color: #c82333; /* Darker red on hover */
}

/* Inspection Button */
.chick-table .inspection-button {
  background-color: #17a2b8; /* Cyan */
  color: white;
}

.chick-table .inspection-button:hover {
  background-color: #138496; /* Darker cyan on hover */
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows pagination buttons to wrap on smaller screens */
}

.pagination-button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.pagination-button:hover {
  background-color: #28a745;
}

.pagination-button.active {
  background-color: #28a745;
}

/* Styles for the inspection history */
.inspection-history-row {
  background-color: #f9f9f9; /* Light background */
}

.inspection-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.inspection-table th,
.inspection-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd; /* Border for cells */
}

.inspection-table th {
  background-color: #f0f0f0; /* Slightly darker background for header */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .chicks-content {
    margin-left: 0; /* Hide sidebar for mobile */
    width: 100%;
    padding-top: 70px; 
  }

  .chick-table th,
  .chick-table td {
    padding: 10px 8px;
  }

  .chick-table table {
    font-size: 0.9rem; 
  }

  .chick-table button {
    width: 100%; /* Full width buttons on smaller screens */
    margin-bottom: 10px; /* Space between stacked buttons */
  }

  .pagination-controls {
    flex-direction: row; /* Keep pagination buttons in a row */
    flex-wrap: wrap; /* Allow wrapping */
  }

  .inspection-table th,
  .inspection-table td {
    padding: 6px; 
  }

  .inspection-table {
    font-size: 0.85rem; /* Smaller text size for mobile view */
  }
}

@media (max-width: 480px) {
  .chicks-content {
    margin-left: 0; /* Hide sidebar for small screens */
    width: 100%;
    padding-top: 70px;
  }

  .chick-table th,
  .chick-table td {
    padding: 8px 6px; 
  }

  .chick-table table {
    font-size: 0.8rem; /* Smaller text for small screens */
  }

  .pagination-controls {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .chick-table button {
    padding: 6px 10px;
    font-size: 12px; /* Smaller font size for buttons on small screens */
  }

  .inspection-table {
    font-size: 0.75rem; /* Even smaller text size for very small screens */
  }

  .inspection-table th,
  .inspection-table td {
    padding: 5px; 
  }
}
