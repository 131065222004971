.signup-container {
  padding: 20px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh; /* Full height to center content vertically */
  justify-content: center; /* Center the content vertically */
}

.signup-header {
  text-align: center;
  margin-bottom: 40px;
}

.signup-header h1 {
  font-size: 2.5rem;
  color: rgb(41, 140, 46); 
}

.signup-header p {
  font-size: 1.2rem;
  color: #777;
}

.signup-content {
  display: flex;
  justify-content: center;
  width: 100%;
}

.signup-form {
  background-color: #fff;
  padding: 30px; 
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 1rem;
  color: #333;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus {
  border-color: rgb(41, 140, 46);
  box-shadow: 0 0 5px rgba(41, 140, 46, 0.3);
}

button[type="submit"] {
  display: inline-block;
  padding: 12px 25px;
  background-color: rgb(41, 140, 46); 
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem; 
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #28a745;
  transform: translateY(-3px);
}

.login-link {
  text-align: center;
  margin-top: 20px;
  font-size: 1rem;
}

.login-link a {
  color: rgb(41, 140, 46); 
  text-decoration: none;
  transition: color 0.3s ease;
}

.login-link a:hover {
  color: #28a745; 
}

/* Responsive Styles */
@media (max-width: 768px) {
  .signup-header h1 {
    font-size: 2rem;
  }

  .signup-header p {
    font-size: 1rem;
  }

  .signup-form {
    padding: 20px;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  .form-group input {
    font-size: 0.9rem;
  }

  button[type="submit"] {
    font-size: 1rem;
  }

  .login-link {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .signup-header h1 {
    font-size: 1.8rem;
  }

  .signup-header p {
    font-size: 0.9rem;
  }

  .signup-form {
    padding: 15px;
  }

  .form-group input {
    padding: 8px;
  }

  button[type="submit"] {
    padding: 10px 20px;
    font-size: 0.9rem;
  }

  .login-link {
    font-size: 0.8rem;
  }
}
