.birds-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px; /* Adjusted to account for the fixed header */
}

.birds-content {
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px); /* Adjusted for the header */
  transition: margin-left 0.3s ease; /* Smooth transition for sidebar toggle */
}


.bird-search {
  margin-bottom: 20px;
}

.bird-search form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; /* Allows elements to wrap on smaller screens */
}

.bird-search input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex-grow: 1;
}

.bird-search button,
.add-bird button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bird-search button:hover {
  background-color: #28a745;
}

.bird-table {
  margin-top: 20px;
  overflow-x: auto;
}

.bird-table table {
  width: 100%;
  border-collapse: collapse;
}

.bird-table th,
.bird-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.bird-table th {
  background-color: #f4f4f4;
}

.bird-table tr:hover {
  background-color: #f1f1f1;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows pagination buttons to wrap on smaller screens */
}

.pagination-button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.pagination-button:hover {
  background-color: #28a745;
}

.pagination-button.active {
  background-color: #28a745;
}

@media (max-width: 768px) {
  .birds-content {
    margin-left: 0; /* Hide sidebar for mobile */
    width: 100%;
    padding-top: 70px; /* Space for the fixed header */
  }

  .bird-search form {
    flex-direction: column; /* Stack form elements */
    gap: 5px; /* Reduce gap for mobile */
  }

  .bird-search input,
  .bird-search button {
    width: 100%; /* Full width on mobile */
  }

  .bird-table th,
  .bird-table td {
    padding: 10px 8px; /* Reduce padding for better fit on mobile */
  }

  .pagination-controls {
    flex-direction: row; /* Keep pagination buttons in a row */
    flex-wrap: wrap; /* Allow wrapping */
  }
}

@media (max-width: 480px) {
  .birds-content {
    margin-left: 0; /* Hide sidebar for small screens */
    width: 100%;
    padding-top: 70px;
  }

  .bird-search form {
    flex-direction: column; /* Stack form elements */
    gap: 5px; /* Reduce gap for smaller screens */
  }

  .bird-search input,
  .bird-search button {
    width: 100%; /* Full width on smaller screens */
  }

  .bird-table th,
  .bird-table td {
    padding: 8px 6px; /* Further reduce padding for smaller screens */
  }

  .pagination-controls {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
