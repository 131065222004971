.header-nav { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: linear-gradient(90deg, #073009, #298c2e);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
  transition: background 0.3s ease;
}

/* Logo */
.main-logo img { 
  height: 50px;
  width: auto;
  transition: transform 0.3s ease;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.3));
}

.main-logo img:hover {
  transform: scale(1.05);
}

/* Navigation Links */
.nav-menu { 
  display: flex;
}

.nav-links { 
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  font-size: 1.3rem;
  color: #fff;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.nav-links a:hover {
  background-color: rgba(255, 204, 0, 0.2);
  transform: translateY(-2px);
}

/* Login Button */
.nav-links li:last-child a {
  background-color: #fff;
  color: #298c2e;
  padding: 5px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-links li:last-child a:hover {
  background-color: #298c2e;
  color: #fff;
}

/* Hamburger Menu Icon */
.menu-toggle {
  display: none;
  font-size: 1.8rem;
  color: #fff;
  cursor: pointer;
}

/* Accent Line */
.header-nav::after {
  content: '';
  display: block;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: fixed; 
    top: 60px;
    right: 20px;
    background-color: #236e27;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    padding: 15px;
    border-radius: 8px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .nav-links.active {
    display: flex;
    opacity: 1;
  }

  .menu-toggle {
    display: block;
  }

  .header-nav {
    justify-content: space-between;
  }
}
