.cages-container {
  display: flex;
  flex-direction: column;
  margin-top: 70px; 
}

.cages-content {
  margin-left: 250px;
  padding: 20px;
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px); 
  transition: margin-left 0.3s ease; /* Smooth transition for sidebar toggle */
}

.cage-search {
  margin-bottom: 20px;
}

.cage-search form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap; /* Allows elements to wrap on smaller screens */
}

.cage-search input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  flex-grow: 1;
  min-width: 200px;
}

.cage-search button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cage-search button:hover {
  background-color: #28a745;
}

.cage-table {
  margin-top: 20px;
  overflow-x: auto;
}

.cage-table table {
  width: 100%;
  border-collapse: collapse;
}

.cage-table th,
.cage-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.cage-table th {
  background-color: #f4f4f4;
}

.cage-table tr:hover {
  background-color: #f1f1f1;
}

.cage-table button {
  padding: 8px 16px;
  background-color: #28a745; 
  color: #fff;
  border: none;
  border-radius: 20px; 
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* subtle shadow */
}

.cage-table button:hover {
  background-color: #218838; /* Darker green on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Increase shadow on hover */
}

.cage-table button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(72, 180, 97, 0.5); /* Adds a focus outline */
}

.birds-list {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.birds-list h4 {
  margin-bottom: 10px;
}

.birds-list ul {
  list-style-type: none;
  padding: 0;
}

.birds-list li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}

.birds-list li:last-child {
  border-bottom: none;
}

.inspection-warning {
  color: red;
  font-size: 16px;
  margin-left: 8px;
}

/* Pagination Styles */
.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap; /* Allows pagination buttons to wrap on smaller screens */
}

.pagination-button {
  padding: 10px 20px;
  background-color: rgb(41, 140, 46);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 5px;
}

.pagination-button:hover {
  background-color: #28a745;
}

.pagination-button.active {
  background-color: #28a745;
}

@media (max-width: 768px) {
  .cages-content {
    margin-left: 0; /* Hide sidebar for mobile */
    width: 100%;
    padding-top: 70px; 
  }

  .cage-search form {
    flex-direction: column; /* Stack form elements */
    gap: 5px; /* Reduce gap for mobile */
  }

  .cage-search input,
  .cage-search button {
    width: 100%; /* Full width on mobile */
  }

  .cage-table th,
  .cage-table td {
    padding: 10px 8px; /* Reduced padding for better fit on mobile */
  }

  .pagination-controls {
    flex-direction: row; /* Keep pagination buttons in a row */
    flex-wrap: wrap; /* Allow wrapping */
  }
}

@media (max-width: 480px) {
  .cages-content {
    margin-left: 0; /* Hide sidebar for small screens */
    width: 100%;
    padding-top: 70px;
  }

  .cage-search form {
    flex-direction: column; /* Stack form elements */
    gap: 5px; /* Reduced gap for smaller screens */
  }

  .cage-search input,
  .cage-search button {
    width: 100%; /* Full width on smaller screens */
  }

  .cage-table th,
  .cage-table td {
    padding: 8px 6px; /* Further reduced padding for smaller screens */
  }

  .pagination-controls {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
